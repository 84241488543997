.profile__main{
	background-color: $color-gray;
	width: 100%;
	max-width: 1400px;
	padding: 5% 10% 10%;
	margin: 100px auto 0;
	border-radius: 20px;
	&:before{
		content: 'Đang tải ...';
		font-size: 16px;
		text-align: center;
		position: absolute;
		left: 0;
		top: 200px;
		width: 100%;
	}

	> div{
		visibility: hidden;
	}
	&.logged{
		&:before{
			content: none;
		}
		> div{
			visibility: visible;
		}
	} 

	h1{
		text-align: left;
		margin-bottom: 20px;
	}
	.profile__data{
		display: flex;
		justify-content: space-between;

		& > div{
			width: 48%;
		}
	}

	.profile__info{
		.form-submit__input{
			display: flex;

			label{
				width: 120px;
			}
		}
	}

	.profile__score{
		margin-top: -6%;
		figure{
			text-align: center;
			img{
				max-width: 250px;
			}
		}
		div{
			text-align: center;
			padding-top: 10px;
			font-size: 30px;
			font-family: 'SamsungSharpSans';
			label{

				vertical-align: baseline;
			}
			strong{
				color: $color-green;
				font-size: 48px;
				vertical-align: baseline;
				display: inline-block;
				padding: 0 45px 0 10px;
				background: url("../images/userscore--icon-coin.png") right center/contain no-repeat;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.profile__main{
		margin-top: 50px;
		padding-top: 20px;
		padding-left: 5%;
		padding-right: 5%;
		
		.profile__data{
			display: block;

			> div{
				width: 100%;
			}

			.form-submit__input{
				display: block;
				label{
					display: block;
					width: 100%;
					line-height: 1;
					margin-bottom: 5px;
				}
				input{
					width: 100%;
					color: gray;
				}
			}
		}
		.profile__score{
			figure {
				padding: 20px 0;
				img{
					max-width: 150px;
				}
			}
		}
		.profile__currentQuest{
			margin: 50px 0;
			.ct{
				width: 100%;
			}
			.token-code{
				padding-top: 10px;
				padding-bottom: 20px;
				display: block;
				.token{
					overflow: hidden;
					width: 100%;
					display: block;
					text-overflow: ellipsis;
					margin-bottom: 5px;
					font-size: 5vw;
				}
				input{
					width: 100%;
					margin-bottom: 20px;
					font-size: 5vw;
				}
			}
		}
	}
}


.profile__currentQuest{
	margin-top: 100px;
	display: none;
	
	h2{

	}
	.txt--desc{
		line-height: 1.5;
		address{
			display: inline;
		}
	}
	.ct{
		max-width: 625px;
		width: 80%;
		margin: auto;
		@media only screen and (max-width:768px){
			width: 100%;
		}
	}
	.token-code{
		background-color: #e0e0e0;
		border-radius: 5px;
		margin: 30px auto 10px;
		max-width: 490px;
		width: 80%;
		padding: 10px 20px;
		text-align: center;
		&.store_must_verify {
			background-color: transparent;
			&.token-code--verified {
				margin-top: 0;
				span {
					padding: 10px 20px;
					border-radius: 5px;
					background-color: #e0e0e0;
				}
			}
		}
		input,
		span.token {
			border: none;
			border-bottom: 2px dotted #000;
			background: transparent;
			text-align: center;
			font-size: 12px;
			font-family: 'SamsungSharpSans';
			width: 80%;
			max-width: 290px;
			margin-right: 20px;

			@media only screen and (max-width:768px){
				margin: 0 auto 10px;
			}

			@media only screen and (min-width:425px){
				font-size: 16px;
			}

			@media only screen and (min-width:768px){
				font-size: 32px;
			}
			
		}

		&--verified {
			opacity: 0.5;
			display: flex;
			align-items: center;
			justify-content: center;

			span.token {
				border-bottom: 0;
				user-select: none
			}
		}
	}

	&.profile__finishQuest .txt--desc address:before{
		content: '✓';
		color: white;
		background-color: green;
		width: 20px;
		height: 20px;
		display: inline-block;
		line-height: 20px;
		font-size: 14px;
		margin-right: 6px;
		border-radius: 100px;
	}
}

// Force hide button close on login popup
#page__profile #gaming-popup-login .btn--close{
	display: none;
}