.store-list {
  margin: 0 auto;
  padding: 40px 0px;
  max-width: 880px;
  width: 100%;
  position: relative;

  .txt--title {
    margin-bottom: 50px;
  }

  .form-submit__input {
    border: 1px solid #c9c9c9;
    border-radius: 3em;
    padding: 0 20px;
    align-items: center;
    margin-bottom: 35px;

    input {
      border: 0;
      padding: 0 10px 0 0;
    }

    .input-icon {
      height: 30px;
      width: 30px;
    }
  }

  &--list {
    border: 1px solid #c9c9c9;
    border-radius: 20px;
    padding: 10px;
    overflow: hidden;
    list-style: none;
    margin: 0;

    li {
      padding: 0 60px;
      position: relative;
      display: flex;
      margin-bottom: 10px;
      font-size: 20px;

      &::before {
        content: '';
        display: block;
        position: relative;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: #D3F3E4;
        margin-right: 15px;
        top: 7px;
        flex-shrink: 0;
      }

      &.group-title {
        padding: 0;
        background: #D3F3E4;
        height: 50px;
        justify-content: center;
        border-radius: 10px;
        margin: 40px 0 20px;
        align-items: center;
        line-height: 1;

        &:first-child {
          margin-top: 0;
        }
      }

      &.item-blank {
        margin: 10px 0;

        &::before {
          display: none;
        }
      }
    }
  }

  .left-bg {
    position: absolute;
    top: 0;
    right: 100%;
    height: 337px;
    width: auto;
  }

  .right-bg {
    position: absolute;
    top: 0;
    left: 100%;
    height: 337px;
    width: auto;
  }
}

.d-none {
  display: none !important;
}

.show-on-pc {
  display: block;
}

.show-on-mb {
  display: none;
}

@media screen and (max-width: 768px) {
  .show-on-pc {
    display: none;
  }

  .show-on-mb {
    display: block;
  }

  .store-list--list li {
    padding: 0;
    font-size: 3.88888889vw;

    &::before {
      margin-right: 10px;
    }
  }
}
