// ---------------------------------------------
// 01 Popup Register

.register,
.popup__wrapper {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: rgba(#000, 0.8);
	display: none;
	z-index: 99;

	&-dialog {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		box-sizing: content-box;
	}
	.popup--overHeight{
		height: auto;
		margin: 20px 0;
	}

	&.active {
		display: block;
	}

	.registar-header {
		display: flex;
		margin: 0 auto;
		width: fit-content;
		font-size: 40px;
		height: 85px;
		justify-content: center;
		align-items: center;
		width: calc(100% - 30px);
		max-width: 864px;
		font-family: 'SamsungSharpSans', arial, sans-serif;
		font-weight: 400;
		font-size: 40px;
		text-align: center;
	}

	.container {
		background: #fff;
		max-width: 864px;
		// border-radius: 30px;
		width: calc(100% - 30px) !important;
		// filter: drop-shadow(0px 0px 6px #00000040);
		padding: 40px 15px 0;
		margin: 0 auto;
		box-shadow: -6px 6px 0 #000;
		border: 2px solid #000;

		.registar-description {
			color: #000;
			text-align: center;
			margin-bottom: 4%;
			font-size: 16px;
		}

		.form-note {
			margin: 10px 0 20px;
		}
	}

	#register-form,
	#forgot-password-form {
		display: flex;
		flex-wrap: wrap;
		max-width: 640px;
		margin: 0 auto;
		padding-bottom: 50px;

		.form-submit-row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			width: 100%;
			margin-bottom: 15px;

			&.form-submit-row--select {
				margin-bottom: 0;
			}
		}

		.form-submit-row--mb-15 {
			margin-bottom: 10px;
		}

		.form-submit-row--mt-15 {
			margin-top: 15px;
		}

		.form-submit-row__two-col {
			.form-submit__group {
				width: calc(50% - 10px);
			}
		}

		.btn-spin {
			margin: 0 auto;

			.btn-loading-text,
			.btn-spin-icon,
			.btn-spin-icon:before {
				display: none;
			}

			.btn-spin-icon {
				transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
			}

			&.loading {
				.btn-spin-text {
					display: none;
				}

				.btn-loading-text {
					display: block;
				}

				.btn-spin-icon {
					z-index: 1;
					display: flex;
					justify-self: center;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;

					.anticon-spin {
						-webkit-animation: loadingCircle 1s linear infinite;
						animation: loadingCircle 1s linear infinite;
						display: inline-block;
					}
				}
			}
		}

		.form__preregister{
			width: 100%;
			background-color: #f4f4f4;
			border-radius: 20px;
			padding: 20px 5% 15px;
			margin-bottom: 20px;

			h2{
				text-align: center;
				font-size: 26px;
			}

			.search{
				margin-bottom: 20px;
				position: relative;

				.select2-container--default .select2-selection--single .select2-selection__arrow b{
					border-width: 12px 12px 0 12px;
					margin-left: -30px;
					margin-top: 5px;
				}
				.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b{
    			border-width: 0 12px 12px 12px;
				}
				.select2-container--default .select2-selection--single{
					line-height: 1;
					height: auto;
					padding-right: 30px;
					width: 100%;
					padding: 0 1em;
					background-color: #f1f1f1;
					border: 1px solid #a8a8a8;
					border-radius: 100px;
					color: #333;
					text-align: left;
					text-align-last: center;
				}
				.select2-container .select2-selection--single{
					height: 50px;
				}
				.select2-container .select2-selection--single .select2-selection__rendered{
					line-height: 50px;
				}
			}
			.form-submit-row{
				margin-bottom: 5%;
			}

			.checkbox-container{
				display: flex;

				figure{
					margin-bottom: -15px;
				}
				.checkboxs{
					align-self: center;
					padding: 0 3%;

					h3{
						text-align: left;
						font-size: 20px;
					}
				}
			}
		}

		@media only screen and (min-width: 768px) and (max-width: 1440px){
			.form__preregister {
				h2{
					font-size: 1.438889vw;
				}
				h3{
					font-size: 1.738889vw;
				}
			}
		}
		
		@media screen and (max-width: 768px){
			.form__preregister h2{
				font-size: 4.438889vw;
			}
			.form__preregister .checkbox-container{
				flex-wrap: wrap;
				width: 83%;
				margin: auto;			
			}
			.form__preregister .checkbox-container .checkboxs h3 {
				font-size: 4.738889vw;
			}
		}
	}

	&.active {
		visibility: visible;

		.container {
			top: 50%;
		}
	}
}

@media screen and (max-width: 768px) {
	.register {
		.registar-header {
			font-size: 24px;
		}

		&-dialog {
			position: relative;
			display: flex;
			align-items: unset;
			justify-content: unset;
			z-index: 9;
			height: unset;
			box-sizing: content-box;
			margin: 5% 0;
		}
	}
}

@media screen and (max-width: 768px) {
	.register {
		h2.registar-header {
			font-size: 30px;
		}
	}
}

// ---------------------------------------------
// 02 Popup Store locator

.popup-location {
	visibility: hidden;

	.wrapper-pu {
		background: #333c;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.detail-pu {
		position: fixed;
		max-width: 860px;
		top: 40%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		width: 100%;
		background-color: #fff;
		border-radius: 30px;
		padding: 40px 0 50px;
		-webkit-transition: all ease 0.5s;
		opacity: 0;

		.header {
			text-align: center;
			margin-bottom: 20px;
			padding: 0 100px;

			.txt--title {
				margin-bottom: 10px;
			}
		}

		.content-pu {
			max-height: 50vh;
			overflow: scroll;
			overflow-x: unset;
			padding: 0 60px 0 100px;
			margin-right: 40px;

			/* width */
			&::-webkit-scrollbar {
				width: 10px;
			}

			/* Track */
			&::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
				border-radius: 10px;
				background-color: #ffffff;
				border: 1px solid #c1c1c1;
			}

			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: #c1c1c1;
				border-radius: 10px;
			}

			/* Handle on hover */
			&::-webkit-scrollbar-thumb:hover {
				background: #c1c1c1;
			}

			.location {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}

			.txt--desc {
				font-weight: 700;
				background-color: #d9f2e5;
				padding: 10px;
				border-radius: 15px;
				width: 100%;
			}

			ul.list-address {
				margin: 20px 0;
				max-width: 450px;
				width: 100%;

				li {
					list-style: disc;

					&::marker {
						font-size: 20px;
						color: #aad7bf;
					}
				}
			}
		}
	}

	&.active {
		visibility: visible;

		.detail-pu {
			opacity: 1;
			top: 50%;
		}
	}

	@media screen and (max-width: 767px) {
		.detail-pu {
			width: calc(100% - 30px);
		}
	}
}

@media screen and (max-width: 768px) {

	.popup-location {
		.detail-pu {
			padding: 40px 0 30px;
			.header {
				padding: 0 20px;
			}

			.content-pu {
				margin: 0 10px;
				padding: 0 10px;

				ul.list-address {
					padding-left: 30px;
				}
			}
		}

		.close-pu {
			right: 15px;
			top: 15px;
			width: 20px;
		}
	}
}


// Overwrite sweetalert
.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation{
	background-color: rgba(0,0,0,.7) !important;
}
.swal2-container.swal2-center>.swal2-popup{
	box-shadow: -6px 6px 0 #000;
	border: 2px solid #000;
	border-radius: 0;
	max-width: 90%;
}
.swal2-title{
	font-family: 'SamsungSharpSans';
	font-weight: 400 !important;
}
.swal2-html-container a{
	color: black;
	text-decoration: underline;
	&:hover{
		text-decoration: none !important;
	}
}