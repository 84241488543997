// ------------------------------------------------
// Reset
body {
	overflow: inherit;

	&.is-show-popup {
		overflow: hidden;
	}
}
body,
html {
	border: 0;
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
	scroll-behavior: smooth;
}


// Variables

// ------------------------
// Color
$color--main: #e9fdf4;
$color-gray: #f4f4f4;
$color-red: 	#ff5656;
$color-green:	#418865;
$color-green-light: #d4f7e8;


// Font
@font-face {
	font-family: 'SamsungOne';
	src: url('../fonts/SamsungOne-400.svg#SamsungOne-400') format('svg'),
		url('../fonts/SamsungOne-400.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'SamsungSharpSans';
	src: url('../fonts/SamsungSharpSansBd.eot');
	src: url('../fonts/SamsungSharpSansBd.eot?#iefix') format('embedded-opentype'),
		url('../fonts/SamsungSharpSansBd.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}


// ------------------------------------------------
// Common
.pc {	display: block !important; }
.mb {	display: none !important; }
@media screen and (max-width: 768px) {
	.pc {	display: none !important;	}
	.mb {	display: block !important; }
}

.txt--unbreakable{ white-space: nowrap; }

b, strong {
	font-family: 'SamsungSharpSans',arial,sans-serif;
	font-weight: normal;
}



// ------------------------------------------------
// Module
.btn--close {
	width: 30px;
	height: 30px;
	position: absolute;
	top: 15px;
	right: 15px;
	cursor: pointer;
	transition: 300ms;
	&:hover{
		transform: rotate(45deg);
	}
	&:before,
	&:after{
		content: '';
		width: 3px;
		height: 100%;
		background-color: #000;
		position: absolute;
		right: 50%;
		top: 0;
		transform: rotate(45deg);
	}
	&:after{
		transform: rotate(-45deg);
	}
	&.btn--close-white:before,
	&.btn--close-white:after{
		background-color: #fff;
	}
	&.btn--close-out{
		top: -60px;
		right: 0;
	}
}

.txt--title {
	margin-bottom: 16px;
	line-height: 1.25;
	color: #000;
	word-wrap: break-word;
	font-family: 'SamsungSharpSans', arial, sans-serif;
	font-size: 38px;
	font-weight: 400;
	text-align: center;
}
.txt--desc {
	font-size: 18px;
	line-height: 1.33;
	text-align: center;
	font-weight: normal;
}
@media only screen and (min-width: 768px) and (max-width: 1440px) {
	.txt--title {
		font-size: 2.63888889vw;
	}
	.txt--desc {
		font-size: 1.25vw;
	}
}
@media screen and (max-width: 768px) {
	.txt--title {
		font-size: 7.22222222vw;
	}

	.txt--desc {
		font-size: 3.88888889vw;
	}
}

// ------------------------
// Form
.form-submit-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 15px;

	&.form-submit-row--select {
		margin-bottom: 0;
	}
}
.form-submit-row--mb-15 {
	margin-bottom: 10px;
}
.form-submit-row--mt-15 {
	margin-top: 15px;
}
.form-submit-row__two-col {
	.form-submit__group {
		width: calc(50% - 10px);
	}
}

.form-submit__group {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	align-items: flex-start;

	.form-submit__error {
		position: relative;
		transition: 1s;
		opacity: 0;
		transform: translateY(-10px);
		height: 0;

		span:before {
			position: relative;
			margin-right: 5px;
			display: inline-flex;
			content: '!';
			width: 0.8em;
			height: 0.8em;
			background: $color-red;
			text-align: center;
			justify-content: center;
			align-items: center;
			color: #fff;
			font-size: 0.9em;
			padding: 0.1em;
			border-radius: 2px;
		}
	}

	&.has-error {
		.form-submit__error {
			color: $color-red;
			padding-top: 15px;
			opacity: 1 !important;
			transform: translateY(0) !important;
			display: block !important;
			height: auto;
		}

		&.form-submit__group100 {
			.form-submit__error {
				padding-top: 10px;
				margin-bottom: 15px;
			}
		}

		.form-submit__input {
			border-bottom-color: $color-red;
		}
	}
}

.form-submit__input {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;

	.text-wrapper,
	input {
		height: 44px;
		position: relative;
	}

	input {
		flex: 1;
		border: 1px solid #c9c9c9;
		border-radius: 40px;

		&:focus {
			outline: none;
			box-shadow: none;
		}
		&:disabled{
			color: #a3a3a3;
			cursor: not-allowed;
		}
	}

	label{
		line-height: 44px;
		padding-right: 10px;
		font-weight: 700;
	}

	.text-wrapper {
		display: inline-flex;
		white-space: nowrap;
		align-items: center;
		margin-right: 10px;

		label.label {
			font-size: 20px;
			line-height: 1;

			&[data-required]::after {
				content: '*';
				color: $color-red;
				display: inline;
				margin-left: 5px;
			}
		}
	}

	input[type='text'],
	input[type='date'],
	input[type='tel'],
	input[type='password'] {
		font-family: SamsungOne, Arial;
		font-size: 18px;
		background: transparent;
		padding: 0 20px;
		position: relative;
		flex: 1;

		&:focus,
		&:hover {
			outline: none;
			box-shadow: none;
		}
	}

	input[type='checkbox'] {
		position: absolute;
		z-index: -1;
		width: 0;
		height: 0;
		border: 0;
		outline: none;
		box-shadow: none;
		top: 50%;

		&:before {
			content: '';
			display: block;
			position: absolute;
			height: 17px;
			width: 17px;
			border-radius: 3px;
			top: -12px;
			border: 1px solid #000;
			left: 0;
		}

		&:checked:after {
			content: '';
			display: block;
			position: absolute;
			height: 4px;
			width: 10px;
			left: 1.6px;
			top: 7px;
			color: #fff;
			border-left: 0.2em solid;
			border-bottom: 0.2em solid;
			transform: rotate(-45deg) translate(11px, -9px);
			opacity: 1;
			border-color: #000;
			z-index: 3;
		}

		& + label {
			font-size: 15px !important;
			font-weight: 400;
			display: inline;
			white-space: normal;
			padding-left: 30px;
			cursor: pointer;
			line-height: 1;

			span{
				font-weight: 700;
			}
		}
	}

	&.form-submit--confirm {
		border-bottom: 0 !important;
		display: flex;
		flex-direction: column;
	}

	.confirm--line {
		position: relative;
		display: flex;
		z-index: 2;
	}
}

.form-submit-row__note {
	font-size: 16px;
	text-align: center;
	position: relative;
	margin-top: 30px;

	p {
		padding: 0;
		margin: 0 auto;
		width: 90%;
		height: 100%;
		position: relative;
		z-index: 2;
		text-align: center;
	}

	&--bg {
		margin: 0;
		padding: 3em 0;
		background: -webkit-linear-gradient(top, #f6f6f6, #fff);

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			height: 50%;
			width: 50%;
		}

		&::before {
			left: 0;
			background: -webkit-linear-gradient(left bottom, #fff, #f6f6f6);
		}

		&::after {
			right: 0;
			background: -webkit-linear-gradient(right bottom, #fff, #f6f6f6);
		}
	}
}

@media screen and (max-width: 768px) {
	.form-submit-row {
		margin-bottom: 5%;
	}
}

@media screen and (max-width: 500px) {
	.form-submit-row {
		&.form-submit-row__two-col {
			flex-direction: column !important;

			.form-submit__group {
				width: 100% !important;

				&:first-child {
					margin-bottom: 5%;
				}
			}
		}
	}
}


// ------------------------
// White box with shadow
.box--white-shadow{
	width: 100%;
	background-color: #fff;
	box-shadow: -6px 6px 0 #000;
	border: 2px solid #000;
	position: relative;
	padding: 30px;

	figure{
		> div{
			position: absolute;
			left: -10%;
			bottom: -10%;
			height: 100%;
			width: 20%;
			img{
				width: auto;
				max-width: 100%;
				position: absolute;
				bottom: 0;
				left: auto;
			}
		}
		> div:first-child{}

		> div:last-child{
			left: auto;
			right: -10%;
		}
	}
}
@media screen and (max-width: 768px) {
	.box--white-shadow{
		padding: 20px;
	}
}



// ------------------------------------------------
// Components

// ------------------------
// Header
header {
	height: 82px;
	margin-bottom: -16px;
	z-index: 10;
	position: relative;
	text-align: center;

	&::before {
		width: 100%;
		height: calc(100% - 16px);
		content: '';
		display: block;
		background: #fff;
		position: absolute;
		border-bottom: 2px solid #000;
		z-index: -1;
	}

	.logo img {
		width: auto;
	}

	.line-icon-burger {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 30px;
		height: 30px;
		position: absolute;
		left: 20px;
		top: 14px;
		justify-content: space-around;
		cursor: pointer;
		z-index: 2;

		span {
			width: 100%;
			height: 3px;
			background-color: #000;
			-webkit-transition: all ease .1s;
		}

		@media screen and (max-width: 768px) {
			top: 6px;
		}
	}

	.burger-menu {
    left: 20px;
    top: 18px;
		-webkit-transition: all ease 0.1s;

		&.active {
			span {
				&:nth-child(1) {
					-webkit-transform: rotate(45deg) translate(5px, 4px);
				}
	
				&:nth-child(2) {
					display: none;
				}
	
				&:nth-child(3) {
					-webkit-transform: rotate(-45deg) translate(7px, -6px);
				}
			}

			&:hover {
				-webkit-transform: rotate(45deg);
				
				span {
					box-shadow: none;
				}
			}
		}

		@media screen and (max-width: 768px) {
			top: 6px;
		}
	}

	.dropdown-menu {
		position: absolute;
    max-width: 300px;
    width: 100%;
    background-color: #fff;
    top: calc(100% - 14px);
    overflow: scroll;
    left: 0;
    -webkit-transform: translate(0, -5%);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all ease 0.5s;
		z-index: 2;
		box-shadow: 4px 6px 5px #6c6b6b94;

		&.active {
			opacity: 1;
			visibility: visible;
			-webkit-transform: translate(0, 0);
		}

		.list-menu {
			li {
				text-align: left;
				 & + li {
					border-top: 1px solid #cfc6c6;
				 }

				a {
					color: #000;
					font-weight: 700;
					padding: 20px;
					display: inline-block;
    			width: 100%;
				}

				&:hover {
					background-color: #e1dede8c;
				}

				&.nav-userProfile{
					display: none;
				}
			}

		}

		.close-dropdown-menu {
			left: unset;
			top: 5px;
			right: 20px;
			-webkit-transition: all ease .3s;
			-webkit-transform: rotate(0);
			display: none;

			span {
				height: 4px;

				&:nth-child(1) {
					-webkit-transform: rotate(45deg) translate(5px, 4px);
				}

				&:nth-child(2) {
					-webkit-transform: rotate(-45deg) translate(7px, -6px);
				}
			}

			&:hover {
				-webkit-transform: rotate(45deg);
			}
		}
	}

	.player__nav {
		display: inline-block;
    position: absolute;
    top: 24px;
    right: 20px;

		ul {
			list-style: none;
			display: inline-flex;
		}

		.player__nav-item {
			> a {
				display: flex;
				align-items: center;
				color: #000;
				font-weight: 400;

				img {
					width: 20px;
				}

				span {
					margin-left: 10px;
					
					&.player_point{
						font-weight: 400;
						color: #ffec62;
						text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000, -2px 2px 0 #000;
						font-family: 'SamsungOne';
						font-size: 17px;
						margin: 0 3px !important;
					}
				}
			}

			&.profile {
				> a {
					.txt-player-name {
						overflow-x: clip;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
						overflow: hidden;
						max-width: 300px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1441px) {
	header {
		.player__nav {
			right: 0;
		}
	}
}

@media screen and (max-width: 992px) {
	header {
		.player__nav {
			.player__nav-item {
				&.profile {
					> a {
						.txt-player-name {
							max-width: 200px;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	header {
		height: 59px;

		&::before {
			height: 42px;
			border-bottom-width: 0.4vw;
		}

		.logo img {
			width: 180px;
			position: relative;
			z-index: 2;
		}

		.dropdown-menu {
			.close-dropdown-menu {
				top: 10px;
	
				span {
					&:nth-child(1) {
						-webkit-transform: rotate(45deg) translate(5px, 6px);
					}
	
					&:nth-child(2) {
						-webkit-transform: rotate(-45deg) translate(5px, -6px);
					}
				}
			}

			.list-menu{
				.nav-userProfile{
					display: block !important;
					background: #FEFCCC;
					padding: 10px 20px;
					text-align: center;
					font-size: 13px;
				}
			}
		}

		.player__nav {
			top: 2vw;
			right: 10px;

			.player__nav-item {
				&.profile {
					> a {
						.txt-player-name {
							max-width: 150px;
						}
					}
				}
			}
		}

		.dropdown-menu {
			max-width: 100%;
			top: calc(100% - 15px);
		}
	}

	.profile{
		display: none;
	}
}

@media screen and (max-width: 480px) {
	header {
		.player__nav {
			.player__nav-item {
				a {
					img {
						width: 27px;
					}
	
					.txt-login-register {
						display: none;
					}
				}

				&.profile {
					> a {
						flex-direction: column-reverse;
						margin-top: -4px;

						img {
							width: 20px;
						}

						.txt-player-name {
							margin: 0;
							max-width: 80px;
							font-size: 14px;
						}
					}
				}
			}
		}
	}
}

// ------------------------
// Footer
footer {
	color: #000;
	max-width: 1440px;
	margin: 40px auto 0;

	img {
		width: 100%;
	}
	a {
		color: #000;
	}
	.container {
		max-width: 1440px;
	}
	.footer .footer-column {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		border-top: 1px solid #ccc;
	}
	
	.footer-bottom {
		position: relative;
		// padding-bottom: 6.11111111vw;
	}
	
	.footer-copyright-wrap {
		position: relative;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		width: 100%;
		height: auto;
		min-height: 96px;
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
		padding: 0 24px;
	}
	
	@media (max-width: 601px) {
		.section-container .footer-bottom .footer-copyright-wrap {
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			border-bottom: 0;
		}
	}
	
	.section-container .footer-bottom .footer-copyright-wrap .footer-copyright-align {
		display: table-cell;
		vertical-align: middle;
		padding: 15px 0;
		font-size: 0.8em;
		line-height: 1.4;
	}
	
	.section-container .footer-bottom .footer-copyright-wrap .footer-copyright-align p {
		margin: 10px 0;
	}
	
	@media (max-width: 601px) {
		.section-container .footer-bottom .footer-copyright-wrap .footer-copyright-align {
			font-size: 0.75em;
		}
	}
	
	.footer-language a {
		color: #000;
		font-size: 0.8em;
		font-weight: 700;
	}
	
	.section-container .footer .footer-bottom .footer-local-logo {
		display: table-cell;
		vertical-align: middle;
		padding: 15px 0;
	}
	
	@media (max-width: 601px) {
		.section-container .footer .footer-bottom .footer-local-logo {
			padding: 0.5em 0;
			margin: 0;
			text-align: left;
			display: block;
		}
		.section-container .footer .footer-bottom .footer-local-logo .footer-local-logo--wrap {
			display: block;
		}
	}
	
	.section-container .footer-bottom .footer-local-logo--wrap {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-flow: row-reverse;
		flex-flow: row-reverse;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	
	@media (max-width: 601px) {
		.footer-local-logo__item:first-child {
			-webkit-box-ordinal-group: 4;
			-ms-flex-order: 3;
			order: 3;
		}
		.footer-local-logo__item:nth-child(2) {
			-webkit-box-ordinal-group: 3;
			-ms-flex-order: 2;
			order: 2;
		}
		.footer-local-logo__item:nth-child(3) {
			-webkit-box-ordinal-group: 2;
			-ms-flex-order: 1;
			order: 1;
		}
	}
	
	@media (max-width: 601px) {
		.footer .footer-column {
			padding: 0;
		}
	}
	
	.section-container .footer-bottom .footer-language-wrap {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding-left: 24px;
		position: relative;
	}
	
	.footer .footer-bottom .footer-language-wrap .footer-sns__link {
		display: flex;
		align-items: center;
		width: 24px;
		height: 24px;
	}
	
	.section-container .footer-bottom .footer-language-wrap .footer-language {
		position: relative;
		padding-left: 1.4em;
	}
	
	.section-container .footer-bottom .footer-language-wrap .footer-language:after {
		content: '|';
		position: absolute;
		right: -20%;
		font-size: 0.8vw;
		color: #d9d9d9;
	}
	
	@media (max-width: 1500px) {
		.section-container .footer-bottom .footer-language-wrap .footer-language:after {
			top: 0.4vh;
		}
	}
	
	@media (max-width: 1100px) {
		.section-container .footer-bottom .footer-language-wrap .footer-language:after {
			font-size: 1.2vw;
			right: -16%;
		}
	}
	
	.section-container .footer-bottom .footer-language-wrap .footer-terms {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		padding: 40px 0;
	}
	
	@media (max-width: 601px) {
		.section-container .footer-bottom .footer-language-wrap .footer-terms {
			padding: 20px 0 10px;
		}
	}
	
	.section-container .footer-bottom .footer-language-wrap .footer-terms__list {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	
	@media (max-width: 601px) {
		.section-container .footer-bottom .footer-language-wrap .footer-terms__list {
			width: 100%;
			padding-left: 0;
			-webkit-box-pack: normal;
			-ms-flex-pack: normal;
			justify-content: normal;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
		}
	}
	
	.section-container .footer-bottom .footer-language-wrap .footer-terms__item {
		font-size: 12px;
		margin-right: 1em;
	}
	
	.section-container .footer-bottom .footer-language-wrap .footer-sns {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		width: 30%;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		margin: 0;
	}
	
	@media (max-width: 601px) {
		.section-container .footer .footer-bottom .footer-language-wrap .footer-sns {
			width: 100%;
			text-align: center;
			display: block;
			padding-bottom: 1em;
			padding: 0;
		}
		.section-container .footer .footer-bottom .footer-language-wrap .footer-sns .footer-sns__title {
			padding-bottom: 0;
		}
	}
	
	.section-container .footer-bottom .footer-language-wrap .footer-sns__list {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		width: 30%;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}
	
	.section-container .footer-bottom .footer-language-wrap .footer-sns__item {
		display: block;
		width: 24px;
		height: 24px;
	}
	
	.section-container .footer-bottom .footer-language-wrap .footer-sns__item img {
		max-width: 100%;
	}
	
	footer#Footer {
		background-color: #fff;
	}
	
	@media (max-width: 1100px) {
		footer#Footer {
			padding: 0 3%;
		}
	}
	
	@media (max-width: 602px) {
		footer#Footer {
			padding: 0;
		}
	}
	
	.accordian {
		width: 100%;
		margin: 0 auto;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		padding: 0 24px;
	}
	
	@media (min-width: 769px) {
		.accordian {
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-ms-flex-direction: row;
			flex-direction: row;
		}
	}
	
	.accordian-item {
		background-color: #fff;
		border-left: 1px solid #ddd;
		-webkit-box-flex: 1;
		-ms-flex: 1 1 100%;
		flex: 1 1 100%;
		-webkit-tap-highlight-color: transparent;
	}
	
	.accordian-item:first-child {
		border-left: none;
	}
	
	.accordian-item:not(:first-child) {
		padding-left: 1%;
	}
	
	@media (max-width: 769px) {
		.accordian-item:not(:first-child) {
			padding-left: 0;
		}
	}
	
	.accordian-item-header {
		font-size: 1.1em;
		min-height: 3.5rem;
		line-height: 1.25rem;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		position: relative;
	}
	
	@media (max-width: 1440px) {
		.accordian-item-header {
			font-size: 1em;
		}
	}
	
	@media (max-width: 1100px) {
		.accordian-item-header {
			font-size: 1em;
		}
	}
	
	@media (max-width: 991px) {
		.accordian-item-header {
			font-size: 1em;
		}
	}
	
	@media (max-width: 765px) {
		.accordian-item-header {
			font-size: 0.95em;
		}
	}
	
	@media (max-width: 601px) {
		.accordian-item-header {
			font-size: 0.8em;
		}
	}
	
	@media (max-width: 480px) {
		.accordian-item-header {
			font-size: 1em;
		}
	}
	
	@media (max-width: 380px) {
		.accordian-item-header {
			font-size: 1em;
		}
	}
	
	.accordian-item-header h4 {
		font-weight: 700;
	}
	
	@media (max-width: 769px) {
		.accordian-item-header {
			padding: 0;
			cursor: pointer;
		}
	}
	
	.accordian-item-header:after {
		position: absolute;
		right: 1rem;
		content: '';
		display: block;
		width: 10px;
		height: 10px;
		border-right: 1px solid #000;
		border-bottom: 1px solid #000;
		transform: rotate(45deg);
	}
	
	@media (min-width: 769px) {
		.accordian-item-header:after {
			display: none;
		}
	}
	
	.accordian-item-header.active:after {
		content: '\f077';
		font-family: Font Awesome\5 Pro;
	}
	
	@media (min-width: 769px) {
		.accordian-item-header.active:after {
			display: none;
		}
	}
	
	.accordian-item-body {
		max-height: 0;
		overflow: hidden;
		-webkit-transition: 0.5s;
		transition: 0.5s;
	}
	
	.accordian-item-body.open {
		max-height: none;
	}
	
	@media (min-width: 769px) {
		.accordian-item-body {
			max-height: unset;
		}
	}
	
	.accordian-item-body-content {
		padding: 0 0.7em 1rem;
		line-height: 1.5;
		font-size: 0.85em;
	}
	
	@media (max-width: 1440px) {
		.accordian-item-body-content {
			font-size: 0.8em;
		}
	}
	
	@media (max-width: 1100px) {
		.accordian-item-body-content {
			font-size: 0.8em;
		}
	}
	
	@media (max-width: 991px) {
		.accordian-item-body-content {
			font-size: 0.8em;
		}
		.footer .footer-bottom .footer-copyright-wrap {
			padding: 0;
		}
	}
	
	@media (max-width: 769px) {
		.accordian {
			padding: 0;
		}
	
		.accordian-item {
			border-left: 0;
		}
	
		.footer .footer-bottom {
			border-top: 1px solid #ddd;
		}
	
		.footer .footer-bottom .footer-copyright-wrap {
			display: flex;
			flex-direction: column;
			padding: 0;
		}
		.section-container .footer-bottom .footer-language-wrap {
			padding: 0;
		}
	
		.footer .footer-bottom .footer-language-wrap .footer-terms__link {
			font-size: 3.33333333vw;
			padding: 2.22222222vw 3.33333333vw;
			line-height: 1.37;
		}
	
		.footer .footer-bottom .footer-language-wrap .footer-sns__title {
			display: block;
			width: 100%;
			height: auto;
			text-align: center;
			font-size: 3.33333333vw;
			padding: 2.22222222vw 0vw;
			padding-bottom: 4.44444444vw;
			line-height: 1.37;
		}
	
		.section-container .footer-bottom .footer-language-wrap .footer-sns {
			flex-direction: column;
			width: 100%;
		}
	
		.footer .footer-bottom .footer-language-wrap .footer-sns__link {
			margin: 2.22222222vw 2.22222222vw;
			width: 6.66666667vw;
			height: 6.66666667vw;
		}
	
		.section-container .footer-bottom .footer-language-wrap .footer-sns__list {
			display: table;
		}
	
		.section-container .footer-bottom .footer-language-wrap .footer-terms__list {
			display: table-cell;
			text-align: center;
		}
	
		.section-container .footer-bottom .footer-language-wrap .footer-sns__item {
			display: table-cell;
		}
	
		.section-container .footer.container .footer-bottom .footer-language-wrap .footer-terms {
			padding: 0;
		}
	
		.section-container .footer-bottom .footer-language-wrap .footer-terms__item {
			display: inline-block;
			font-size: 3.33333333vw;
			list-style: none;
			text-decoration: none;
			margin-right: 0;
		}
	
		.section-container .footer-bottom .footer-language-wrap .footer-terms__item a {
			display: block;
			padding: 2.22222222vw 2.8333333vw;
		}
	}
	
	@media (max-width: 765px) {
		.accordian-item-body-content {
			font-size: 0.9em;
		}
	}
	
	@media (max-width: 601px) {
		.section-container .footer-bottom .footer-language-wrap {
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column-reverse;
		}
	
		.section-container .footer-bottom .footer-language-wrap .footer-sns__list {
			margin: auto;
			padding-top: 3%;
		}
	
		footer .container {
			padding: 0 16px;
		}
	
		.accordian-item-body-content {
			font-size: 0.9em;
		}
	}
	
	@media (max-width: 480px) {
		.accordian-item-body-content {
			font-size: 0.9em;
		}
	}
	
	@media (min-width: 769px) {
		.accordian-item-body-content {
			padding: 0;
		}
	}
	
	.accordian-item-body-content ul li {
		padding: 0.5em 0;
		position: relative;
	}
	
	.accordian-item-body-content ul li a {
		display: block;
	}
	
	.accordian-item-body-content ul li a:hover {
		color: #1428a0;
		text-decoration: underline;
	}
	
	.accordian-item-body-content ul li a .arrow {
		-webkit-transform: rotate(45deg);
		-khtml-transform: rotate(45deg);
		transform: rotate(45deg);
	}
	
	@media only screen and (max-width: 1440px) {
		footer .footer-column,
		.footer-copyright-wrap,
		.section-container .footer-bottom .footer-language-wrap .footer-terms,
		.section-container .footer-bottom .footer-language-wrap .footer-sns {
			padding-left: 1.66666667vw;
			padding-right: 1.66666667vw;
		}
	}
	
	@media only screen and (max-width: 769px) {
		.accordian-item {
			border-bottom: 1px solid #ddd;
		}
		.accordian-item:last-child {
			border: none;
		}
		.footer-copyright-wrap {
			padding: 0;
			border-top: none;
		}

		.container {
			padding: 0 10px;
		}
	}
	
}
