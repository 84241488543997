.section-content {
  .hero-banner-gaming {
    position: relative;
    display: block;

    @media screen and (max-width: 768px) {
      margin: 0 -16px;
    }

    .content-box {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;

      .left-box {
        left: 15%;
        display: inline-block;
        top: 80px;
        position: absolute;

        #map {
          position: relative;
          z-index: 2;
        }

        .thumb-game {
          position: absolute;
          top: 0;
          z-index: 2;
          a {
            display: block;
          }
        }

        .chess-board {
          height: 440px;
          width: 440px;
          position: relative;

          .point {
            width: 100%;
            position: absolute;
            width: calc(100% / 3);

            .card {
              padding: 2px 6px 2px;
            }

            &:nth-child(1) {
              left: 0;
              top: 0
            }

            &:nth-child(2) {
              left: calc((100% /2) - (100%/3/2));
              top: 0
            }

            &:nth-child(3) {
              right: 0;
              top: 0
            }

            &:nth-child(4) {
              right: 0;
              top: calc((480px / 2) - 90px);
            }

            &:nth-child(5) {
              right: 0;
              bottom: 0;
            }

            &:nth-child(6) {
              left: calc((100% /2) - (100%/3/2));
              bottom: 0;
            }

            &:nth-child(7) {
              left: 0;
              bottom: 0;
            }

            &:nth-child(8) {
              left:0;
              top: calc((480px / 2) - 90px);
            }

            img {
              background-color: #fffccc;
              border-radius: 20px;
              border: 2px solid #000;
              box-shadow: -2px 3px 1px #000;
              -webkit-transition: all ease .5s;

              @media only screen and (max-width:768px){
                border-radius: 10px;
              }
            }

            &.active {
              img {
                background-color: #96ffde;
                border-color: #4bd9af;
                box-shadow: -7px 7px 1px #000;
                transform: translate(4px, -4px);
              }
            }
          }
        }

        .btn-dice {
          position: absolute;
          top: 50%;
          left: 49%;
          background-color: transparent;
          border: none;
          box-shadow: none;
          -webkit-transform: translate(-50%, -50%);
          width: 170px;
          height: 170px;
          z-index: 4;
          opacity: 0;
          -webkit-transition: all ease .3s;
          cursor: pointer;

          &:hover {
            width: 175px;
          }

          &.active {
            visibility: visible;
            opacity: 1;
          }

          img {
            width: 100%;
            background-color: transparent;
            border: none;
            box-shadow: none;
          }
        }

        @-webkit-keyframes bounce {
          0%,
          20%,
          53%,
          to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
          }
          40%,
          43% {
            -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
            transform: translate3d(0, -30px, 0) scaleY(1.1);
          }
          70% {
            -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
            transform: translate3d(0, -15px, 0) scaleY(1.05);
          }
          80% {
            -webkit-transform: translateZ(0) scaleY(0.95);
            transform: translateZ(0) scaleY(0.95);
            -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          }
          90% {
            -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
            transform: translate3d(0, -4px, 0) scaleY(1.02);
          }
        }
        @keyframes bounce {
          0%,
          20%,
          53%,
          to {
            -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
          }
          40%,
          43% {
            -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
            transform: translate3d(0, -30px, 0) scaleY(1.1);
          }
          70% {
            -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
            transform: translate3d(0, -15px, 0) scaleY(1.05);
          }
          80% {
            -webkit-transform: translateZ(0) scaleY(0.95);
            transform: translateZ(0) scaleY(0.95);
            -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          }
          90% {
            -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
            transform: translate3d(0, -4px, 0) scaleY(1.02);
          }
        }

      }

      .right-box {
        position: absolute;
        right: 8%;
        top: 70px;
        text-align: center;
        max-width: 600px;
        width: 100%;

        .txt-start {
          font-size: 44px;
          color: #ffec62;
          text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000, -3px 2px 0 #000;
          line-height: 50px;
          margin-bottom: 10px;

          span {
            font-size: 29px;
            color: #fff;
          }
        }

        .group-steps {
          display: flex;
          justify-content: space-around;

          .step {
            align-items: center;
            display: flex;
            flex-direction: column;

            .step-detail {
              margin-top: auto;
              font-size: 12px;

              .txt-step span {
                margin-bottom: 5px;
                display: inline-block;
                color: #ffec62;
                font-size: 14px;
                text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000, -2px 2px 0 #000;

                @media only screen and (max-width:768px){
                  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
                }
              }
            }
          }

          img {
            max-width: 112px;
            margin-bottom: 20px;
          }
        }

        .hero-description {
          position: relative;
          margin-top: 30px;

          .txt-description {
            position: absolute;
            width: 77%;
            font-size: 14px;
            top: 42%;
            left: 47%;
            -webkit-transform: translate(-50%, -50%);
          }
        }
      }

      @media only screen and (min-width: 768px) and (max-width: 1440px) {
        .left-box {
          left: 15vw;
          top: 6vw;

          .chess-board {
            width: 33vw;
            height: 33vw;

            .point {
              &:nth-child(4) {
                top: 11.3vw;
              }

              &:nth-child(8) {
                top: 11.3vw;
              }
            }
          }

          .btn-dice {
            width: 40%;
            top: 18vw;
            height: auto;

            &:hover {
              width: 41%;
            }
          }
        }

        .right-box {
          width: 41vw;
          right: 5vw;
          top: 6vw;

          .txt-start {
            font-size: 3vw;
            line-height: 1;
            margin: 0 0 5px;

            span {
              font-size: 2.5vw;;
            }
          }

          .group-steps {
            img {
              width: 9vw;
              margin-bottom: 1vw;
            }
          }

          .hero-description {
            margin-top: 1vw;

            .txt-description {
              font-size: 1.2vw;
            }
          }
        }
      }

      @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 50%;
        -webkit-transform: translate(0, -50%);

        .left-box, .right-box {
          position: relative;
        }

        .left-box {
          position: relative;
          top: unset;
          left: unset;

          .chess-board {
            width: 300px;
            height: 300px;

            .point {
              &:nth-child(4),
              &:nth-child(8) {
                top: 103px;
              }

              &-9 {
                img {
                  width: 130%;
                  left: -18px;
                }
              }
            }
          }

          .btn-dice {
            width: 33%;
            top: 158px;
            height: auto;

            &:hover {
              width: 34%;
            }
          }
        }

        .right-box {
          position: relative;
          top: unset;
          right: unset;
          width: calc(100% - 40px);
          margin-top: 20px;

          .txt-start {
            font-size: 7vw;
            line-height: 1.1;

            span {
              font-size: 6vw;
            }
          }

          .group-steps {
            justify-content: space-evenly;

            img {
              width: 16vw;
              margin-bottom: 2vw;
            }
          }

          .hero-description {
            margin-top: 3vw;

            .txt-description {
              font-size: 2.8vw;
              b{
                font-weight: 400;
                font-family: 'SamsungOne';
              }
            }
          }
        }
      }

      @media only screen and (max-width: 420px) {
        .right-box {
          .group-steps {
            .step {
              img {
                width: 13vw;
              }
              .step-detail {
                font-size: 3vw;
                padding-left: 5px;
                padding-right: 5px;
                p b{
                  font-family: 'SamsungOne';
                }
              }
            }
          }
        }
      }
    }
  }
}

.gaming-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: #000000cc;
  visibility: hidden;

  .content-pu {
    width: 90%;
    max-width: 800px;
    position: fixed;
    background-color: #fff;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    box-shadow: -6px 6px 0 #000;
    -webkit-transition: all ease .3s;
    visibility: hidden;
    opacity: 0;

    .text-detail p{
      margin-bottom: 20px;

      a{
        color: black;
        text-decoration: underline;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }

  &.active {
    visibility: visible;

    .content-pu {
      opacity: 1;
      top: 50%;
      visibility: visible;
    }
  }

  .btn-close {
    width: 29px;
    height: 29px;
    position: absolute;
    top: -60px;
    right: 0;
    cursor: pointer;
  }
}

.gaming-popup-quest {
  .content-pu {
    .header {
      .txt-tit-pu {
        font-size: 40px;
        text-shadow: -2px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, -3px 2px 0 #000, -5px 4px 0 #7b7c7b;
        color: #fff;
        position: absolute;
        top: -70px;
        text-align: center;
        width: 100%;
        padding-left: 35px;
        padding-right: 35px;

        span {
          display: inline-block;
          font-size: 50px;
          margin-top: -8px;
          color: #ffec62;
        }
      }
    }

    .txt-mission {
      font-size: 30px;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, -2px 2px 0 #000, -2px 2px 0 #000;
      color: #ffec62;
      position: absolute;
      top: -15px;
      left: 20px;
    }

    .detail {
      padding: 40px 40px 30px 100px;

      .success_messege {
        display: none;
        text-align: center;
        padding-right: 60px;

        &.active {
          display: block;
        }
      }

      .group-box {
        height: 300px;
        overflow-x: unset;
        overflow-y: scroll;
        padding-right: 100px;
        display: none;

        &.active {
          display: block;
        }

        ul {
          li {
            margin-bottom: 10px;
          }
        }

        .list-radio-btn {
          margin: 0 30px;

          .container-label {
            display: block;
            position: relative;
            padding-left: 35px;
            padding-bottom: 10px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            height: fit-content;

            &:hover input ~ .checkmark {
              background-color: #ccc;
            }

            &:has(input:checked) {
              font-weight: 700;
            }

            input {
              position: absolute;
              opacity: 0;
              cursor: pointer;

              &:checked ~ .checkmark {
                background-color: #51bb9a;

                &:after {
                  display: block;
                }
              }
            }
          }

          .checkmark {
            position: absolute;
            top: -2px;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #eee;
            border-radius: 50%;

            &:after {
              content: "";
              position: absolute;
              display: none;
            }
          }

          .container-label .checkmark:after {
            left: 7px;
            top: 4px;
            width: 4px;
            height: 9px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            border-radius: 0;
          }
        }

        .description {
          padding-top: 10px;

          p.text-center {
            text-align: left;
            margin-top: 30px;
          }

          ul {
            margin: 0 30px;
          }
        }

        p {
          margin-bottom: 20px;
        }

        /* width */
        &::-webkit-scrollbar {
          width: 11px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px #808080;
          border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #adadad;
          border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #adadad;
        }
      }

      .btn-mission {
        margin-top: 20px;
        position: relative;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
      }

      .icon-woman {
        width: 200px;
        position: absolute;
        left: -90px;
        bottom: -55px;
      }

      .icon-dice {
        width: 127px;
        position: absolute;
        right: 60px;
        bottom: -40px;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    .content-pu {
      .header {
        .txt-tit-pu {
          top: -5.5vw;
          font-size: 3.2vw;

          span {
            font-size: 4vw;
          }
        }
      }

      .txt-mission {
        font-size: 3vw;
      }

      .detail {
        padding-left: 80px;

        .group-box {
          padding-right: 50px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content-pu {
      .header {
        .txt-tit-pu {
          top: -12vw;
          font-size: 5vw;

          span {
            font-size: 6.75vw;
          }
        }
      }

      .txt-mission {
        font-size: 5vw;
        top: -2.7vw;
      }

      .detail {
        padding: 30px 20px;

        .success_messege {
          padding: 0 20px;
        }

        .group-box {
          font-size: 2.5vw;
          padding-right: 20px;
        }

        .icon-woman {
          width: 27vw;
          left: -8vw;
        }

        .icon-dice {
          width: 20vw;
          right: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 460px) {
    .content-pu {
      .header {
        .txt-tit-pu {
          font-size: 5vw;
          top: -52px;
          // top: -15vw;

          span {
            font-size: 7.6vw;
          }
        }
      }
      .detail {
        .group-box {
          font-size: 3.7vw;
          height: 350px;

          .list-radio-btn,
          .description ul {
            margin: 0 10px;
          }
        }
      }
    }
  }
}

.gaming-popup-challenge {
  .content-pu {
    padding: 40px 100px;
    text-align: center;

    .text-detail {
      line-height: 1.5;

      span {
        color: #ffec62;
        font-size: 20px;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000, -2px 2px 0 #000;
        display: inline-block;
        margin-top: -4px;
      }

      .token-code{
        background-color: #e0e0e0;
        border-radius: 5px;
        margin: 30px auto 10px;
        max-width: 490px;
        width: 80%;
        padding: 10px 20px;
        text-align: center;
    
        input{
          border: none;
          border-bottom: 2px dotted #000;
          background: transparent;
          text-align: center;
          font-size: 25px;
          font-family: 'SamsungSharpSans';
          width: 80%;
          max-width: 250px;
          margin-right: 20px;
          pointer-events: none;
          cursor: not-allowed;
        }
      }
    }

    .gaming-icon-man {
      width: 200px;
      position: absolute;
      left: -130px;
      bottom: -40px;
    }

    .icon-dice {
      width: 127px;
      position: absolute;
      right: -40px;
      bottom: -20px;
    }
  }

  @media screen and (max-width: 768px) {
    .content-pu {
      padding: 20px 7vw;

      .text-detail {
        span {
          font-size: 4vw;
        }
        .token-code {
          width: 100%;
          padding: 20px 10px;

          input {
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
      }

      .gaming-icon-man {
        width: 23vw;
        left: -7vw;
        bottom: -17vw;
      }

      .icon-dice {
        width: 20vw;
        right: 0;
        bottom: -8vw;
      }
    }
  }

  @media screen and (max-width: 460px) {
    .content-pu {
      .text-detail {
        font-size: 4vw;

        span {
          font-size: 4.5vw;
          margin-top: -2px;
        }
      }
    }
  }
}

.gaming-popup-login {
  .content-pu {
    // max-width: 60%;
    max-width: 720px;
    padding: 50px;
    text-align: center;

    .tit-login {
      font-size: 40px;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, -3px 3px 0 #000, -5px 4px 0 #959595;
      position: absolute;
      top: -63px;
      color: #fff;
      text-align: center;
      width: 100%;
      left: 0;
    }

    .group-account {
      max-width: 400px;
      margin: 0 auto;

      > div {
        display: flex;
        margin: 20px 0;
        align-items: center;
      }

      input {
        flex: 1;
        min-height: 40px;
        border-radius: 20px;
        padding: 0 10px;
        border-style: unset;
        border: 1px solid #bababa;
        color: #000;
        padding-top: 2px;

        &::placeholder {
          color: #a3a3a3;
        }
      }
    }

    .btn-login button {
      min-width: 130px;
      margin: 0 auto;
    }

    .btn-user-register {
      position: relative;
      z-index: 2;
      margin-top: 20px;
      padding-left: 10%;
      padding-right: 10%;

      a {
        color: #0060e7;
      }
    }

    .icon-login-pu {
      width: 250px;
      position: absolute;
      bottom: -90px;
      right: -55px;
    }

    @media only screen and (max-width: 768px) {
      padding: 20px;

      .tit-login {
        font-size: 35px;
      }

      .group-account {
        max-width: 100%;
      }

      .icon-login-pu {
        width: 30vw;
        right: -3vw;
        bottom: -11vw;
      }
    }

    @media only screen and (max-width: 460px) {
      .icon-login-pu {
        width: 27vw;
        right: -7vw;
        bottom: -14vw;
      }
    }
  }
}

.gaming-popup {
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    .content-pu {
      max-width: 80%;
    }
  }

  @media only screen and (max-width: 768px) {
    .content-pu {
      max-width: calc(100% - 40px);
    }

    .btn-close {
      top: -12.5vw;
    }
  }

  @media only screen and (max-width: 460px) {
    .btn-close {
      top: -16vw;
    }
  }
}

.game__dice-messeges {
  position: fixed;
  right: 15px;
  bottom: -100%;
  width: 280px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 15px;
  color:white;
  transition: bottom 0.5s ease-in-out;
  z-index: 1000;
  border-radius: 8px;

  &.active {
    bottom: 15px;
    transition: bottom 0.5s ease-in-out;
  }
}

#animationDice{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  display: none;
  text-align: center;
  &.active{
    display: block;
  }
  #df-img{ 
    display: none;
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
  canvas{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.checkin__wrapper {
  position: fixed;
  right: 15px;
  bottom: 30px;
  display: flex;

  .left-content {
    max-width: 120px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .box-text {
      background: #ffff;
      box-shadow: 0px 0px 5px 1px #264f3b66;
      border-radius: 5px;
      padding: 20px 10px 15px;
      font-size: 12px;
      line-height: 16px;
      position: relative;
      margin-bottom: 10px;

      .close-box {
        position: absolute;
        width: 15px;
        height: 15px;
        background: #000;
        border-radius: 50%;
        top: 5px;
        right: 5px;
        cursor: pointer;

        svg {
          width: 9px;
          fill: #fff;
        }
      }
    }
  }

  .dog-icon {
    width: 80px;
    margin-left: 10px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  #checkin-btn{
    display: flex;
    border: none;
    cursor: pointer;
    width: 120px;
    height: 50px;
    justify-content: center;
    align-items: center;
    background: url('../images/checkin-icon.png');
    background-size: 100%;

    span {
      font-weight: 700;
      display: inline-block;
      margin-left: 15px;
      text-transform: uppercase;
      font-size: 14px;
      margin-top: 3px;
    }
  }
}