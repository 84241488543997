#container {
	width: 100%;
	height: 100%;
	background: #fff;
  }

.background-gradient {
	text-transform: uppercase;
	-webkit-appearance: none;
	// background: -webkit-gradient(to right, #37d0fc 0%, #15a9fe 100%);
	// background: linear-gradient(to right, #37d0fc 0%, #15a9fe 100%);
	background-image: linear-gradient(to right, #37d0fc 0%, #3a7bd5 51%, #37d0fc 100%);
	background-size: 500%;
	border: none;
	border-radius: 5rem;
	box-shadow: 6px 5px 1rem 2px #03a9f45c;
	color: #fff;
	cursor: pointer;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	outline: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.section-container {
	max-width: 1440px;
	margin: 0 auto;
	position: relative;
	font-size: 16px;
	font-family: 'SamsungOne', arial, sans-serif;

	.d-flex {
		display: flex;
	}

	.w-100 {
		width: 100%;
	}

	.w-50 {
		width: 50%;
	}

	.text-center {
		text-align: center;
	}

	.btn-general {
		min-width: 207px;
		padding: 0.9rem 2rem 0.6rem; //1440
		display: flex;
		align-items: center;
		justify-content: center;
	}

	img {
		width: 100%;
	}
}

/*Form*/

$spacing: rem;

$padding-list: (0, 1, 2, 3, 4, 5);

$side: (
	all: 'all',
	t: 'top',
	r: 'right',
	b: 'bottom',
	l: 'left',
	y: 'y-axis',
	x: 'x-axis',
);

@each $key in $padding-list {
	@each $j, $val in $side {
		@if $j == all {
			.p--#{$key} {
				padding: #{$key}#{$spacing};
			}
			.m--#{$key} {
				margin: #{$key}#{$spacing};
			}
		} @else if $j == y {
			.py--#{$key} {
				padding-top: #{$key}#{$spacing};
				padding-bottom: #{$key}#{$spacing};
			}
			.my--#{$key} {
				margin-top: #{$key}#{$spacing};
				margin-bottom: #{$key}#{$spacing};
			}
		} @else if $j == x {
			.py--#{$key} {
				padding-left: #{$key}#{$spacing};
				padding-right: #{$key}#{$spacing};
			}
			.mx--#{$key} {
				margin-left: #{$key}#{$spacing};
				margin-right: #{$key}#{$spacing};
			}
		} @else {
			.p#{$j}--#{$key} {
				padding-#{$val}: #{$key}#{$spacing};
			}
			.m#{$j}--#{$key} {
				margin-#{$val}: #{$key}#{$spacing};
			}
		}
	}
}
/*End-form*/





.swal2-container .swal2-popup {
	border-radius: 30px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
	box-shadow: 0 0 0 30px white inset !important;
}

@keyframes loadingCircle {
	100% {
		transform: rotate(1turn);
	}
}

.swal2-styled {
	&.swal2-confirm {
		background: #000 !important;
		border-radius: 100px !important;
		font-size: 14px !important;
		padding: 10px 24px 11px !important;

		&:focus {
			background: #555 !important;
			box-shadow: 0 0 0 2px #fff !important;
			outline: 2px dotted black !important;
		}

		&:hover {
			background-color: #555 !important;
			color: #eee !important;
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 576px) {
}

@media screen and (max-width: 500px) {
}

@media only screen and (max-width: 460px) {
}

@media only screen and (max-width: 320px) {
}