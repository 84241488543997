.headline-kv {
	font-family: 'SamsungSharpSans', arial, sans-serif;
	font-weight: 400;
	font-size: 48px;
	line-height: 1.2;
	word-wrap: break-word;
}

#page-loading {
	span {
		text-align: center;
	}

	img {
		width: 60%;
	}
}

.home-kv-desc {
	margin-top: 24px;
	font-size: 18px;
	line-height: 1.33;
	word-wrap: break-word;
}

.home-cta-wrap {
	margin-top: 20px;
}

.column-new__headline {
	font-size: 24px;
	line-height: 1.33;
	font-weight: 700;
	text-transform: capitalize;
}

.product-description {
	font-size: 14px;
	font-weight: normal;
	word-wrap: break-word;
	line-height: 1.33;
}

.section-content {
	overflow: hidden;

	.hero-banner {
		position: relative;
		background-color: #E9FDF4;

		.detail-hero-banner {
			position: absolute;
			top: 50%;
			max-width: 630px;
			width: 100%;
			text-align: center;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			display: flex;
			align-items: center;
			flex-direction: column;

			.middle-img-kv {
				width: 294px;
				height: 188px;
				margin: 10px 0 0;
			}
		}
	}

	.section-award {
		.group-award {
			display: flex;
			justify-content: center;
			margin: 0 auto;
			max-width: 1200px;
		}

		.left-block,
		.right-block {
			width: 50%;
			margin: 0 10px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-direction: column;
			position: relative;
			padding: 20px 30px 35px;
			text-align: center;

			.tit-award {
				margin: 0 0 20px;
				display: flex;
				flex-direction: column;
			}
		}

		.left-block {
			background: url('../images/left-box.png') no-repeat;
			background-size: 100%;

			.group-prod {
				display: flex;
				justify-content: space-between;
				width: 100%;

				> div img{
					max-width: 13vw;
				}
			}

			.icon-xuc-xac {
				left: -50px;
				position: absolute;
				top: -40px;
				width: 130px;
				animation: float 1s ease-in-out infinite;
			}
			@keyframes float {
				0% {
					transform: translatey(0px);
				}
				50% {
					transform: translatey(5px);
				}
				100% {
					transform: translatey(0px);
				}
			}
		}

		.right-block {
			background: url('../images/right-box.png') no-repeat;
			background-size: 100%;
			justify-content: flex-start;

			.phone {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			.icon-phone {
				max-width: 200px;
				margin-bottom: 20px;
				img{
					width: 100%;
					max-width: 16vw;
				}
			}

			.icon-kid {
				max-width: 170px;
				position: absolute;
				bottom: -20px;
				right: -55px;
				animation: float2 6s ease-in-out infinite;
				
				@keyframes float2 {
					0% {
						transform: translatey(0px);
					}
					50% {
						transform: translatey(-20px);
					}
					100% {
						transform: translatey(0px);
					}
				}
			}

			.txt--desc {
				font-weight: 700;
			}
		}

		.btn-section-award {
			margin: 20px 0;
			text-align: center;
		}
	}

	.section-voucher {
		padding-bottom: 40px;

		.content-voucher {
			max-width: 1200px;
			margin: 0 auto;
		}
		.list-item {
			display: flex;
			align-items: center;
			width: 100%;
			justify-content: center;

			.item {
				padding: 20px;
				margin: 0 10px;
				border-radius: 10px;
				border: 1px solid #a1a1a1;
				text-align: center;
				width: 50%;

				.btn-section-voucher {
					margin: 20px 0;
				}
				figure{
					text-align: center;
					width: 100%;
					border-radius: 15px;
					img {
						border-radius: 15px;
						overflow: hidden;
						max-width: 344px;
					}
				}

				&:first-child figure{
					background-color: #fffccc;
				}
				&:last-child figure{
					background-color: #f4f4f4;
				}
			}
		}
	}

	.section-guide {
		.group-box-guide {
			display: flex;
			justify-content: center;
			margin: 0 auto;
			max-width: 1200px;

			.box-general {
				width: 50%;
				margin: 0 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				position: relative;

				.box-content {
					min-height: 370px;
					width: 100%;
					border-radius: 20px;
					height: 100%;
				}
			}

			.left-box {
				.box-content {
					background-color: #fffbcc;
					display: flex;
					flex-direction: column;
					justify-content: space-evenly;
					overflow: hidden;
				}

				.step {
					display: flex;
					position: relative;
					max-width: 80%;
					width: 100%;
					justify-content: center;
					display: flex;
					flex-direction: column;
					padding: 20px 30px;
					font-size: 18px;

					p {
						font-size: 16px;
					}

					img {
						width: 120px;
						position: absolute;
					}

					&-1 {
						background-color: #feda60;
						margin-left: auto;
						padding-left: 80px;

						img {
							left: -70px;
						}
					}

					&-2 {
						background-color: #f3a3a6;
						padding-right: 80px;
						text-align: right;

						img {
							right: -50px;
						}
					}

					&-3 {
						background-color: #d1c4fa;
						margin-left: auto;
						padding-left: 80px;

						img {
							left: -55px;
						}
					}
				}
			}

			.right-box {
				.box-content {
					background-color: #d7f8e8;
					padding: 50px;

					.item-dice {
						margin-bottom: 15px;
						display: flex;
						align-items: center;

						img {
							width: 100%;
							margin-right: 15px;
							flex-basis: 90px;
						}

						.detail {
							font-size: 16px;
							flex: 1;
						}
					}

					.note {
						font-size: 14px;
						text-align: center;
						padding-top: 10px;
					}
				}
			}
		}

		.section-btn{
			text-align: center;
			padding: 40px 0;
		}
	}

	@media screen and (max-width: 767px) {
		padding: 0 16px;

		.hero-banner,
		.section-voucher {
			margin: 0 -16px;
		}

		.section-guide {
			.group-box-guide {
				flex-direction: column;

				.box-general {
					width: 100%;
					margin: 0;

					&.left-box {
						margin-bottom: 40px;

						.step {
							img {
								width: 110px;
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 460px) {
		.section-guide {
			.group-box-guide {
				.left-box {
					.step {
						font-size: 16px;
						padding-top: 15px;
						padding-bottom: 15px;

						p {
							font-size: 14px;
						}

						img {
							width: 90px;
						}

						&-1,
						&-3 {
							padding-left: 65px;
						}

						&-2 {
							padding-right: 65px;
						}
					}
				}

				.right-box {
					.box-content {
						padding: 30px 20px;

						.item-dice {
							img {
								flex-basis: 70px;
								width: 70px;
							}
							.detail {
								font-size: 3.28888889vw;
							}
						}
					}
				}
			}
		}
	}
}


@media only screen and (min-width: 768px) and (max-width: 1440px) {
	.headline-kv {
		font-size: 3.33333333vw;
	}

	.home-kv-desc {
		margin-top: 1.66666667vw;
		font-size: 1.25vw;
	}

	.home-cta-wrap {
		margin-top: 2vw;
	}

	.column-new__headline {
		font-size: 1.66666667vw;
	}

	.product-description {
		font-size: 0.97222222vw;
	}

	.section-content {
		.section-award {
			.left-block {
				.icon-xuc-xac {
					width: 11vw;
					left: 0;
					top: -60px;
				}
			}

			.right-block {
				.icon-kid {
					width: 13vw;
					bottom: -2vw;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.headline-kv {
		font-size: 8.03333333vw;
		line-height: 1.33;
	}

	.home-kv-desc {
		margin-top: 4.44444444vw;
		font-size: 3.88888889vw;
	}

	.home-cta-wrap {
		margin-top: 6vw;
	}

	.column-new__headline {
		font-size: 4.44444444vw;
	}

	.product-description {
		font-size: 3.23333333vw;
	}

	.section-content {
		.hero-banner {
			.detail-hero-banner {
				.middle-img-kv {
					width: 50vw;
					height: auto;
				}

				.home-kv-desc {
					padding: 0 10vw;
				}
			}
		}

		.section-award {
			.group-award {
				flex-direction: column;

				.left-block,
				.right-block {
					width: 100%;
					margin: 0;
					min-height: 65vw;
				}

				.left-block {
					margin-bottom: 4vw;
					padding: 0 15px;

					.icon-xuc-xac {
						top: -20px;
						width: 16vw;
						left: 0;
					}

					.group-prod img {
						width: 20vw;
					}
				}

				.right-block {
					.icon-phone {
						max-width: 30vw;
					}

					.icon-kid {
						max-width: 22vw;
						right: 0;
					}
				}
			}

			.btn-section-award {
				margin-top: 4vw;
			}
		}

		.section-voucher {
			.list-item {
				flex-direction: column;
				margin: 0;
				padding: 0 16px;

				.item {
					width: 100%;
					margin: 0;
					margin-bottom: 20px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 576px) {
}

@media screen and (max-width: 500px) {
}

@media only screen and (max-width: 460px) {
	.section-content {
		.section-award {
			.group-award {
				.right-block {
					min-height: 60vw;
					.icon-phone {
						max-width: 22vw;
					}
				}

				.left-block {
					min-height: 60vw;

					.group-prod img {
						max-width: 14vw;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 320px) {
	h2 {
		font-size: 18px !important;
	}
}





// --------------------------------------
// Section leaderboard
.home__leaderboard{
	border: 1px solid #adadad;
	padding: 18px;
	border-radius: 20px;
	max-width: 1200px;
	margin: 0 auto 30px;	
	position: relative;

	article{
		background-color: #f4f4f4;
		border-radius: 20px;
		padding: 4% 16% 4% 10%;
	}
	ol{
		display: grid;
		grid-template-columns: 50% 50%;	
		gap: 0;
    grid-template: repeat(5, 1fr) / repeat(2, 1fr);
    grid-auto-flow: column;
		
		li{
			width: 80%;
			font-size: 20px;
			height: 60px;
			line-height: 60px;
			border-bottom: 2px solid #d2d2d2;
			list-style-type: decimal;
			list-style-position: inside;
			// padding-left: 20px;
			position: relative;
			// margin-left: 33px;
			&:first-child:after{
				content: '';
				display: block;
				width: 58px;
				height: 58px;
				position: absolute;
				left: -70px;
				top: 50%;
				transform: translateY(-50%);
				background: url('../images/leaderboard--icon-top1.png') left center/contain no-repeat;
			}
			& > div{
				display: flex;
				position: absolute;
				left: 34px;
				top: 0;
				width: 93%;
				height: 100%;
				justify-content: space-between;
			}
			.username{
				text-overflow: ellipsis;
				overflow: hidden;
				width: 70%;
				max-width: 270px;
				white-space: nowrap;
				display: inline-block;
			}
			.userscore{
				font-family: 'SamsungSharpSans';
				font-weight: 400;
				position: relative;
				padding-right: 24px;
				&:after{
					content:'';
					display: block;
					width: 16px;
					height: 25px;
					position: absolute;
					right: 0;
					bottom: 50%;
					transform: translateY(50%);
					background: url("../images/userscore--icon-coin.png") center center/contain no-repeat;
				}
			}
			// &:after{
			// 	content: '';
			// 	width: 100%;
			// 	height: 2px;
			// 	background-color: #d2d2d2;
			// 	position: absolute;
			// 	bottom: 0;
			// 	left: -20px;
			// }
		}
	}
	figure{
		width: 22%;
		height: 100%;
		position: absolute;
		right: -5%;
		bottom: -5%;
		background: url('../images/leaderboard--vs.png') center bottom/contain no-repeat;
		text-indent: -9999vw;
	}

	&.home__leaderboard--currentUser{
		margin-bottom: 11px;

		ol{
			grid-template: repeat(1, 1fr)/repeat(2, 1fr);
		}
		article{
			background-color: $color-green-light;
			padding-top: 10px;
			padding-bottom: 10px;

			li{
				border: none;

				&:first-child:after{
					display: none;
				}
				.no{
					background-color: $color-green-light;
					position: absolute;
					left: -44px;
					padding-left: 5px;
					padding-right: 5px;
					width: 35px;
					@media only screen and (max-width:768px){
						left: -34px;
						width: 30px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	#home__leaderboard{
		.txt--desc{
			padding: 0 5%;
		}
	}
	.home__leaderboard{
		padding: 9px;
		border-radius: 10px;
		article{
			border-radius: 10px;
			padding-left: 0;
			padding-right: 0;
		}
		figure{
			bottom: -7%;
			right: -7%;
		}
		ol{
			display: block;
			padding-left: 45px;
			li{
				font-size: 4vw;
				width: 90%;
				height: 40px;
				line-height: 40px;

				&:first-child:after{
					width: 30px;
					height: 30px;
					left: -37px;
				}
				> div{
					left: 24px;
					font-size: 4vw;
				}
			}
		}	
	}
}